import axios from 'axios';
const api = axios.create({
    baseURL: 'https://api.trumpfighting.net/',
});

// 请求拦截
api.interceptors.request.use(
    (config) => {
        // 设置请求头 application/json
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



function getUserInfo(id) {
    return api.post(`/api/tg`, {
        id
    });
}

function getRankData(id) {
    return api.get(`/api/top-scores?tgid=${id}`);
}

function complateTask(params) {
    // tg_id转为整数
    params.tg_id = parseInt(params.tg_id);
    return api.post(`/api/task_status`, params);
}



export {
    getUserInfo,
    getRankData,
    complateTask
};
export default api;